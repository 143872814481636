import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import styled from 'styled-components'
import { Theme } from '../../theme'
import VehicleIcon from '../gfx/VehicleIcon'
import { useDictionary } from '../hooks/useDictionary'
import { NotificationsContext } from '../notifications/NotificationsContext'

// ███████╗████████╗██╗   ██╗██╗     ███████╗██████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔══██╗
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ██║  ██║
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ██║  ██║
// ███████║   ██║      ██║   ███████╗███████╗██████╔╝
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚═════╝
//

const Option = styled.div`
	padding: 12px 15px 12px;
	color: ${Theme.colors.link};
	border-bottom: 1px solid ${Theme.colors.border.lightGray};

	&:hover,
	&.focus {
		cursor: pointer;
		background: ${Theme.colors.bg.blue};
		color: ${Theme.colors.blue};
	}
`

const Header = styled.div`
	display: flex;
	align-items: baseline;
	gap: 8px;
`

const Title = styled.div`
	flex: 1;
	font-size: 18px;
	font-weight: bold;
`

const Meta = styled.div`
	margin-top: 2px;
	display: flex;
	flex-wrap: wrap;
	opacity: 0.7;
	> * {
		font-size: 13px;
		display: inline-block;
		&:not(:last-child):after {
			content: '|';
			display: inline-block;
			margin: 0 4px;
		}
	}
`

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//

const LineAutocompleteSelectOption = props => {
	const line = props.data
	const { t } = useDictionary('lines.categories')

	const notifications = React.useContext(NotificationsContext)

	const lineNotifications = React.useMemo(
		() => (notifications ? notifications.filter(n => n.for_line_numbers.includes(line.number)) : []),
		[line, notifications]
	)

	return (
		<Option {...props.innerProps} className={props.isFocused ? 'focus' : ''} ref={props.innerRef}>
			<Header>
				<VehicleIcon vehicle={line.transport_type} />
				<Title>
					{line.public_number} {line.name}
				</Title>
				{lineNotifications.length > 0 && <FontAwesomeIcon icon={faExclamationTriangle} size='sm' />}
			</Header>
			<Meta>{line.category_key && <span>{t(line.category_key)}</span>}</Meta>
		</Option>
	)
}
export default LineAutocompleteSelectOption
