import * as React from 'react'
import styled, { css } from 'styled-components'

// ███████╗████████╗██╗   ██╗██╗     ███████╗██████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔══██╗
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ██║  ██║
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ██║  ██║
// ███████║   ██║      ██║   ███████╗███████╗██████╔╝
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚═════╝
//

const Item = styled.button`
	background: none;
	border: none;
	color: inherit;
	font-size: 18px;
	padding: 15px 0;
	cursor: pointer;
	opacity: 1;

	&:hover {
		opacity: 1;
	}

	${props =>
		props.isSelected &&
		css`
			border-bottom: 3px solid white;
			padding-bottom: 12px;
			font-weight: bold;
			opacity: 1;
		`}
`

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//

const RadioOptionsItem = ({ label, onClick, isSelected = false }) => {
	return (
		<Item isSelected={isSelected} onClick={onClick} role={'radio'} aria-checked={isSelected}>
			{label}
		</Item>
	)
}
export default RadioOptionsItem
