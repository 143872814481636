import { faExclamationTriangle, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { apiCall } from '../../api/apiCalls'
import { Theme } from '../../theme'
import LineAutocomplete from '../forms/LineAutocomplete'
import WidgetHeader from '../gfx/WidgetHeader'
import { useDictionary } from '../hooks/useDictionary'
import { useWidget } from '../hooks/useWidget'
import { NotificationsContext } from '../notifications/NotificationsContext'
import NotificationDetails from '../lists/NotificationDetails'
import ArrowLink from '../gfx/ArrowLink'
import { Config } from '../../config'

// ███████╗████████╗██╗   ██╗██╗     ███████╗██████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔══██╗
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ██║  ██║
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ██║  ██║
// ███████║   ██║      ██║   ███████╗███████╗██████╔╝
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚═════╝
//

const Container = styled.div`
	max-width: 400px;
`

const Loading = styled.div`
	display: flex;
	width: 100%;
	height: 240px;
	align-items: center;
	justify-content: center;
	opacity: 0.15;
`
const Error = styled.p`
	font-weight: bold;
	color: ${Theme.colors.red};
`
const NoResults = styled.p`
	font-weight: normal;
	color: #000;
`

const LineNotificationsContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 8px;
	border-radius: 4px;
	padding: 16px;
	gap: 8px;
	background: #eff6fb;
`

const LineTitle = styled.div`
	font-weight: bold;
`

const VerstoringWidgetContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
`

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//

const VerstoringenWidget = () => {
	const { t } = useDictionary('verstoringen')
	const { language } = useWidget()

	// State
	const [isLoading, setIsLoading] = useState(true)
	const [notifications, setNotifications] = useState(null)
	const [error, setError] = useState(null)
	const [selectedLine, setSelectedLine] = useState(null)

	// Load
	const loadNotifications = useCallback(async () => {
		setIsLoading(true)
		setError(null)
		try {
			const records = await apiCall('/notifications', {
				include: 'lines',
				'filter[nearly_visible]': true,
				'filter[concession]': 'rrreis',
				'filter[enhanced]': true,
			})
			setNotifications(records)
		} catch (error) {
			setError(error)
		} finally {
			setIsLoading(false)
		}
	}, [language])
	useEffect(() => {
		loadNotifications()
	}, [loadNotifications])

	// Selected notifications
	const lineNotifications = React.useMemo(() => {
		if (!notifications || !selectedLine) return null
		return notifications.filter(n => n.for_line_numbers.includes(selectedLine.number))
	}, [notifications, selectedLine])

	return (
		<Container>
			<WidgetHeader icon={<FontAwesomeIcon icon={faExclamationTriangle} />} title={t('title')} />
			{isLoading ? (
				<Loading>
					<FontAwesomeIcon size={'2x'} icon={faSpinner} spin />
				</Loading>
			) : error ? (
				<Error>{t('error')}</Error>
			) : notifications.length === 0 ? (
				<NoResults>{t('noResults')}</NoResults>
			) : (
				<NotificationsContext.Provider value={notifications}>
					<VerstoringWidgetContainer>
						<LineAutocomplete value={selectedLine} onChange={v => setSelectedLine(v)} />
						{selectedLine ? (
							<LineNotificationsContainer>
								<LineTitle>
									{selectedLine.public_number} {selectedLine.name}
								</LineTitle>
								{lineNotifications && lineNotifications.length > 0 ? (
									<>
										{lineNotifications.map(n => (
											<NotificationDetails notification={n} key={n.id} />
										))}
									</>
								) : (
									<div>{t('noNotifications')}</div>
								)}
							</LineNotificationsContainer>
						) : (
							<strong>
								{notifications.length === 1
									? t('activeCount.singular')
									: t('activeCount.multiple', { count: notifications.length })}
							</strong>
						)}

						{/* All. */}
						<ArrowLink href={`${Config.siteUrl}/${language}/rrreis/meldingen`} style={{ marginTop: 16 }}>
							{t('viewAll')}
						</ArrowLink>
					</VerstoringWidgetContainer>
				</NotificationsContext.Provider>
			)}
		</Container>
	)
}
export default VerstoringenWidget
