import { faTrain } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { get } from 'lodash'
import * as React from 'react'
import styled from 'styled-components'
import { Theme } from '../../theme'

import { HalteIcon } from '../../assets/extraIcons'

// ███████╗████████╗██╗   ██╗██╗     ███████╗██████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔══██╗
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ██║  ██║
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ██║  ██║
// ███████║   ██║      ██║   ███████╗███████╗██████╔╝
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚═════╝
//

const Option = styled.div`
	padding: 12px 15px;
	color: ${Theme.colors.link};
	border-bottom: 1px solid ${Theme.colors.border.lightGray};

	&:hover,
	&.focus {
		cursor: pointer;
		background: ${Theme.colors.bg.blue};
		color: ${Theme.colors.blue};
	}
`

const Header = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`

const Title = styled.div`
	flex: 1;
	font-size: 18px;
	font-weight: bold;
`

const Meta = styled.div`
	margin-top: 2px;
	display: flex;
	flex-wrap: wrap;
	opacity: 0.7;
	> * {
		font-size: 13px;
		display: inline-block;
		&:not(:last-child):after {
			content: '|';
			display: inline-block;
			margin: 0 4px;
		}
	}
`

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//

const StopAutocompleteSelectOption = props => {
	const stop = props.data

	const lineType = get(stop, 'line_information.0.type')

	return (
		<Option {...props.innerProps} className={props.isFocused ? 'focus' : ''} ref={props.innerRef}>
			<Header>
				{lineType === 'Train' ? <FontAwesomeIcon icon={faTrain} /> : <HalteIcon />}
				<Title>{stop.name}</Title>
			</Header>
			<Meta>{stop.city && <span>{stop.city}</span>}</Meta>
		</Option>
	)
}
export default StopAutocompleteSelectOption
