import AwesomeDebouncePromise from 'awesome-debounce-promise'
import { addDays, format, formatISO, setHours, setMinutes, startOfWeek } from 'date-fns'
import { flatten, groupBy, values } from 'lodash'
import { apiCall } from './apiCalls'

//  █████╗ ██╗   ██╗████████╗ ██████╗  ██████╗ ██████╗ ███╗   ███╗██████╗ ██╗     ███████╗████████╗███████╗
// ██╔══██╗██║   ██║╚══██╔══╝██╔═══██╗██╔════╝██╔═══██╗████╗ ████║██╔══██╗██║     ██╔════╝╚══██╔══╝██╔════╝
// ███████║██║   ██║   ██║   ██║   ██║██║     ██║   ██║██╔████╔██║██████╔╝██║     █████╗     ██║   █████╗
// ██╔══██║██║   ██║   ██║   ██║   ██║██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║     ██╔══╝     ██║   ██╔══╝
// ██║  ██║╚██████╔╝   ██║   ╚██████╔╝╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ███████╗███████╗   ██║   ███████╗
// ╚═╝  ╚═╝ ╚═════╝    ╚═╝    ╚═════╝  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝     ╚══════╝╚══════╝   ╚═╝   ╚══════╝
//
const autoCompleteFunctions = {}

export const locationAutocomplete = language => {
	const key = `${language}/rrreis`
	if (!autoCompleteFunctions[key]) {
		autoCompleteFunctions[key] = AwesomeDebouncePromise(
			async query => {
				// Get locations
				try {
					return await apiCall(`/directions/9292/locations`, {
						query,
						language,
						concession: 'rrreis',
					})
				} catch (error) {
					console.error(error)
					return []
				}
			},
			250,
			{}
		)
	}

	return autoCompleteFunctions[key]
}

// ██████╗ ██████╗  ██████╗ ██████╗ ██╗   ██╗ ██████╗████████╗     █████╗ ██████╗ ██╗   ██╗██╗ ██████╗███████╗
// ██╔══██╗██╔══██╗██╔═══██╗██╔══██╗██║   ██║██╔════╝╚══██╔══╝    ██╔══██╗██╔══██╗██║   ██║██║██╔════╝██╔════╝
// ██████╔╝██████╔╝██║   ██║██║  ██║██║   ██║██║        ██║       ███████║██║  ██║██║   ██║██║██║     █████╗
// ██╔═══╝ ██╔══██╗██║   ██║██║  ██║██║   ██║██║        ██║       ██╔══██║██║  ██║╚██╗ ██╔╝██║██║     ██╔══╝
// ██║     ██║  ██║╚██████╔╝██████╔╝╚██████╔╝╚██████╗   ██║       ██║  ██║██████╔╝ ╚████╔╝ ██║╚██████╗███████╗
// ╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═════╝  ╚═════╝  ╚═════╝   ╚═╝       ╚═╝  ╚═╝╚═════╝   ╚═══╝  ╚═╝ ╚═════╝╚══════╝
//

const productAdviceFunctions = {}
export const getProductAdviceOptions = language => {
	const key = `${language}/rrreis`
	if (!productAdviceFunctions[key]) {
		productAdviceFunctions[key] = AwesomeDebouncePromise(
			async (from, to, vehicle_types) => {
				// Get locations
				try {
					// Create basic options
					const options = {
						from_location: from.id,
						to_location: to.id,
						vehicle_types,
						time_type: 'Departure',
						plan_with_accessibility: false,
					}

					// Now create the moments
					const sundayAfternoon = setHours(startOfWeek(addDays(new Date(), 7), 0), 14)
					const wednesdayMorning = setMinutes(setHours(addDays(sundayAfternoon, 3), 8), 30)
					const fridayEvening = setHours(addDays(wednesdayMorning, 2), 17)

					// And create the payloads, and do the calls
					const payloads = [sundayAfternoon, wednesdayMorning, fridayEvening].map(date => ({
						...options,
						date: formatISO(date, { representation: 'date' }),
						time: format(date, 'HH:mm'),
					}))

					const promises = payloads.map(payload =>
						apiCall('/directions/9292/routes', {}, true, {
							method: 'get',
							params: payload,
						})
					)
					const results = await Promise.all(promises)

					// Combine the trips from all the results
					const trips = flatten(
						results.map((result, index) => {
							// Get the trip
							const trips = result[0].journeys

							// Get the matching timestamp too
							return trips.map((trip, originalIndex) => ({
								...trip,
								originalIndex,
								payload: payloads[index],
							}))
						})
					)
						// Filter out haltettaxi's
						.filter(trip => !trip.legs.some(l => l.mode_name === 'Haltetaxi'))

					return { error: null, trips: groupTripsByRoute(trips) }
				} catch (error) {
					return { error, trips: [] }
				}
			},
			250,
			{}
		)
	}

	return productAdviceFunctions[key]
}

export const groupTripsByRoute = trips => {
	// Group it
	const grouped = groupBy(trips, trip => {
		// Get non-walk legs
		const legs = trip.legs.filter(leg => leg.mode_type !== 'Walk')
		return legs.map(leg => `${leg.mode_name}|${leg.service || leg.destination}|${leg.operator_name}`).join('//')
	})

	// Now just take the first one from each
	return values(grouped).map(group => group[0])
}
