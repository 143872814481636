import {
	faBus,
	faQuestionCircle,
	faShip,
	faSubway,
	faTrain,
	faTram,
	faWalking,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { useMemo } from 'react'

const AssetIcon = ({ leg }) => {
	const faIcon = useMemo(() => {
		switch (leg.mode_type === 'Unknown' ? leg.mode_name : leg.mode_type) {
			case 'Train':
				return faTrain

			case 'Walk':
				return faWalking

			case 'Bus':
				return faBus

			case 'Tram':
				return faTram

			case 'Metro':
			case 'Subway':
				return faSubway

			case 'Boat':
			case 'Ferry':
			case 'Ship':
				return faShip

			default:
				return faQuestionCircle
		}
	}, [leg])

	return <FontAwesomeIcon icon={faIcon} />
}
export default AssetIcon
