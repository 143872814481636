import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { useMemo } from 'react'
import styled from 'styled-components'
import { Theme } from '../../theme'
import { getAutocompleteIcon } from '../../utils/icons'

// ███████╗████████╗██╗   ██╗██╗     ███████╗██████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔══██╗
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ██║  ██║
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ██║  ██║
// ███████║   ██║      ██║   ███████╗███████╗██████╔╝
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚═════╝
//

const Option = styled.div`
	padding: 12px 15px;
	color: ${Theme.colors.link};
	border-bottom: 1px solid ${Theme.colors.border.lightGray};

	&:hover,
	&.focus {
		cursor: pointer;
		background: ${Theme.colors.bg.blue};
		color: ${Theme.colors.blue};
	}
`

const Header = styled.div`
	display: flex;
	align-items: center;
`

const Title = styled.div`
	flex: 1;
	font-size: 18px;
	font-weight: bold;
`

const Meta = styled.div`
	margin-top: 2px;
	display: flex;
	flex-wrap: wrap;
	opacity: 0.7;
	> * {
		font-size: 13px;
		display: inline-block;
		&:not(:last-child):after {
			content: '|';
			display: inline-block;
			margin: 0 4px;
		}
	}
`

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//

const LocationAutocompleteSelectOption = props => {
	const location = props.data

	const icon = useMemo(() => getAutocompleteIcon(location), [location])

	return (
		<Option {...props.innerProps} className={props.isFocused ? 'focus' : ''} ref={props.innerRef}>
			<Header>
				<FontAwesomeIcon icon={icon} style={{ marginRight: '8px' }} />
				<Title>{location.display_name}</Title>
			</Header>
			<Meta>
				<span>{location.type_name}</span>
				{location.place.name && location.place.name !== location.display_name && (
					<span>{location.place.name}</span>
				)}
			</Meta>
		</Option>
	)
}
export default LocationAutocompleteSelectOption
