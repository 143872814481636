import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { useMemo } from 'react'
import Skeleton from 'react-loading-skeleton'
import styled, { css } from 'styled-components'
import { Theme } from '../../theme'
import SelectRouteItemLeg from './SelectRouteItemLeg'

// ███████╗████████╗██╗   ██╗██╗     ███████╗██████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔══██╗
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ██║  ██║
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ██║  ██║
// ███████║   ██║      ██║   ███████╗███████╗██████╔╝
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚═════╝
//

const EmptyCheck = styled.div`
	box-sizing: border-box;
	width: 18px;
	height: 18px;
	border: 2px solid ${Theme.colors.border.lightGray};
	border-radius: 2px;
`
const Check = styled.div`
	flex-shrink: 0;
	width: 18px;
`
const Container = styled.button`
	font-size: 18px;
	border-radius: 4px;
	border: 1px solid ${Theme.colors.border.lightGray};
	margin-bottom: 6px;
	background: white;
	padding: 18px 12px;
	display: flex;
	align-items: center;
	width: 100%;

	&:hover {
		cursor: pointer;
		border-color: ${Theme.colors.border.blue};
		${EmptyCheck} {
			border-color: ${Theme.colors.border.blue};
		}
	}

	${props =>
		props.isSelected &&
		css`
			background: ${Theme.colors.bg.blue};
			border-color: 1px solid ${Theme.colors.border.blue};
		`}
`
const Partitions = styled.div`
	flex-grow: 1;
	align-items: center;
	margin-left: 12px;
	display: flex;
	flex-wrap: wrap;
	> * {
		margin-right: 12px;
	}
	> span {
		flex-grow: 1;
	}
`

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//

const SelectRouteItem = ({ trip = null, isSelected = false, onClick }) => {
	// Get the non walk partitions
	const legs = useMemo(() => {
		if (!trip) return null
		return trip.legs.filter(leg => leg.mode_type !== 'Walk')
	}, [trip])

	return (
		<Container isSelected={isSelected} onClick={onClick} role='radio'>
			<Check>
				{isSelected ? <FontAwesomeIcon icon={faCheckSquare} color={Theme.colors.blue} /> : <EmptyCheck />}
			</Check>
			<Partitions>
				{trip ? (
					<>
						{legs.map((leg, index) => (
							<React.Fragment key={index}>
								{index > 0 && <FontAwesomeIcon icon={faArrowRight} color={Theme.colors.blue} />}
								<SelectRouteItemLeg key={index} leg={leg} />
							</React.Fragment>
						))}
					</>
				) : (
					<Skeleton height={20} />
				)}
			</Partitions>
		</Container>
	)
}
export default SelectRouteItem
