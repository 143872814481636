import { trim } from 'lodash'

/**
 * SLUGIFY
 */
export function slugify(str) {
	return trim(
		dasherize(
			cleanDiacritics(str)
				.replace(/[^\w\s-]/g, '-')
				.toLowerCase()
		),
		'-'
	)
}

/**
 * DIACRITICS
 */

const diacriticFrom = `ąàáäâãåæăćčĉęèéëêĝĥìíïîĵłľńňòóöőôõðøśșşšŝťțţŭùúüűûñÿýçżźž${'ąàáäâãåæăćčĉęèéëêĝĥìíïîĵłľńňòóöőôõðøśșşšŝťțţŭùúüűûñÿýçżźž'.toUpperCase()}ß`
const diacrititTo = `aaaaaaaaaccceeeeeghiiiijllnnoooooooossssstttuuuuuunyyczzz${'aaaaaaaaaccceeeeeghiiiijllnnoooooooossssstttuuuuuunyyczzz'.toUpperCase()}`.split(
	''
)
diacrititTo.push('ss')
export function cleanDiacritics(value) {
	return value.replace(/.{1}/g, function (c) {
		var index = diacriticFrom.indexOf(c)
		return index === -1 ? c : diacrititTo[index]
	})
}

/**
 * Dasherize
 */

export function dasherize(str) {
	return trim(str)
		.replace(/([A-Z])/g, '-$1')
		.replace(/[-_\s]+/g, '-')
		.toLowerCase()
}

export function capitalize(str, lowercaseRest = false) {
	const remainingChars = !lowercaseRest ? str.slice(1) : str.slice(1).toLowerCase()
	return str.charAt(0).toUpperCase() + remainingChars
}
