import { isEmpty } from 'lodash'
import * as React from 'react'
import styled from 'styled-components'
import { Config } from '../../config'
import { slugify } from '../../utils/strings'
import Button from '../gfx/Button'
import { useDictionary } from '../hooks/useDictionary'
import { useWidget } from '../hooks/useWidget'

// ███████╗████████╗██╗   ██╗██╗     ███████╗██████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔══██╗
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ██║  ██║
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ██║  ██║
// ███████║   ██║      ██║   ███████╗███████╗██████╔╝
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚═════╝
//

const Item = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
`
const Title = styled.div`
	font-weight: normal;
`

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//

const NotificationDetails = ({ notification }) => {
	const { language } = useWidget()
	const { t } = useDictionary('verstoringen')

	const baseUrl = React.useMemo(() => `${Config.siteUrl}/${language}/rrreis/meldingen`, [language])

	if (isEmpty(notification.title[language])) return null

	return (
		<Item key={notification.id}>
			<Title>{notification.title[language]}</Title>
			<Button as={'a'} href={`${baseUrl}/${notification.id}-${slugify(notification.title[language])}`} block>
				{t('moreInfo')}
			</Button>
		</Item>
	)
}
export default NotificationDetails
