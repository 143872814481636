import React, { useMemo } from 'react'
import { WidgetContext } from './useWidget'

// ███╗   ██╗██╗
// ████╗  ██║██║
// ██╔██╗ ██║██║
// ██║╚██╗██║██║
// ██║ ╚████║███████╗
// ╚═╝  ╚═══╝╚══════╝
//
const NL = {
	rrreisplanner: {
		header: (
			<>
				<em>RRReis</em> planner
			</>
		),
		intro: 'Vul je adres, halte of station in en plan je reis',
		location: {
			from: 'Van',
			to: 'Naar',
		},
		arrival: 'Aankomst',
		departure: 'Vertrek',
		date: {
			today: 'Vandaag',
			tomorrow: 'Morgen',
		},
		labels: {
			date: 'Datum',
			time: 'Tijd',
		},
		now: 'Nu',
		extraOptions: 'Toon extra opties',
		submit: 'Plan mijn reis',
	},
	haltes: {
		title: 'Haltes en stations',
		intro: 'Zoek je halte of station en bekijk de actuele vertrektijden. Bijvoorbeeld Apeldoornseweg.',
		search: {
			placeholder: 'Zoek een halte of station',
			loading: 'Zoeken...',
			noResults: {
				forQuery: ({ query }) => `Geen haltes of stations gevonden voor ${query}`,
				empty: 'Zoek een halte of station op naam of plaatsnaam',
			},
		},
		button: 'Vind mijn halte of station',
	},
	lines: {
		search: {
			placeholder: 'Zoek op lijnnummer',
			loading: 'Zoeken...',
			noResults: {
				forQuery: ({ query }) => `Geen lijnen gevonden voor ${query}`,
				empty: 'Zoek een lijn op lijnnummer of bestemming',
			},
		},
		categories: {
			comfortrrreis: 'comfortRRReis',
			snelrrreis: 'snelRRReis',
			streeklijnen: 'Streeklijnen',
			'apeldoorn-stadsdienst': 'Apeldoorn Stadsdienst',
			'zwolle-stadsdienst': 'Zwolle Stadsdienst',
			'harderwijk-stadsdienst': 'Harderwijk Stadsdienst',
			'deventer-stadsdienst': 'Deventer Stadsdienst',
			'ede-stadsdienst': 'Ede Stadsdienst',
			scholierenlijnen: 'Scholierenlijnen',
			buurtrrreis: 'buurtRRReis',
			reserveerrrreis: 'reserveerRRReis',
			trein: 'Trein',
			unknown: 'Algemeen',
			'enschede-stad': 'Enschede Stad',
			'hengelo-stad': 'Hengelo Stad',
			'almelo-stad': 'Almelo Stad',
			'regio-twente': 'Regio Twente',
			'flex-rrreis': 'FlexRRReis',
			flevo: 'Flevoland',
		},
	},
	productadvies: {
		title: 'Voer je reis in en kies je route',
		intro: 'Voor welke reis wil je een los kaartje of abonnementsadvies?',
		from: 'Van',
		to: 'Naar',
		selectRoute: 'Selecteer de route die je (meestal) gebruikt en klik op ‘Kies deze route’.',
		button: 'Kies deze route',
		assets: {
			Train: ({ leg }) => `${leg.mode_name} richting ${leg.destination}`,
			Bus: ({ leg }) =>
				/RRReis/.test(leg.operator_name) ? (
					<>
						<em>{leg.operator_name}</em> lijn {leg.service}
					</>
				) : (
					`${leg.operator_name} ${leg.service}`
				),
		},
		noTrips: 'Er zijn voor de gekozen reis geen routes gevonden.',
	},
	verstoringen: {
		title: 'Werkzaamheden en verstoringen',
		error: 'Er is iets misgegaan bij het ophalen van de werkzaamheden en verstoringen.',
		noResults: 'Er zijn op dit moment geen werkzaamheden of verstoringen.',
		moreInfo: 'Meer info',
		noNotifications: 'Er zijn geen werkzaamheden of verstoringen actief op deze lijn.',
		viewAll: 'Bekijk alle werkzaamheden en verstoringen',
		activeCount: {
			multiple: ({ count }) => `${count} werkzaamheden/verstoringen actief`,
			singular: 'Één verstoring actief',
		},
	},
	weer: {
		geolocationError: 'Het is niet gelukt om jouw locatie te bepalen.',
	},
	locationAutocomplete: {
		placeholder: 'adres, postcode of station',
		loading: 'Zoeken...',
		noResults: {
			forQuery: ({ query }) => `Geen locaties gevonden voor ${query}`,
			empty: 'Vul een adres of station in om een locatie te zoeken',
		},
		type: {
			station: 'Station',
			poi: 'POI',
			halte: 'Halte',
			adres: 'Adres',
		},
	},
}

// ███████╗███╗   ██╗
// ██╔════╝████╗  ██║
// █████╗  ██╔██╗ ██║
// ██╔══╝  ██║╚██╗██║
// ███████╗██║ ╚████║
// ╚══════╝╚═╝  ╚═══╝
//
const EN = {
	rrreisplanner: {
		header: 'Travel planner',
		intro: 'Fill in your address, stop, or station and plan your journey',
		location: {
			from: 'From',
			to: 'To',
		},
		arrival: 'Arrival',
		departure: 'Departure',
		date: {
			today: 'Today',
			tomorrow: 'Tomorrow',
		},
		labels: {
			date: 'Date',
			time: 'Time',
		},
		now: 'Now',
		extraOptions: 'Show extra options',
		submit: 'Plan my journey',
	},
	haltes: {
		title: 'Stops and times',
		intro: 'Find your stop and view the real time departures. For example Apeldoornseweg.',
		search: {
			placeholder: 'Find a stop',
			loading: 'Searching...',
			noResults: {
				forQuery: ({ query }) => `No stops found for ${query}`,
				empty: 'Find a stop by name or city',
			},
		},
		button: 'Find my stop',
	},
	verstoringen: {
		title: 'Alerts',
		error: 'An error has occured while loading the alerts.',
		noResults: 'There are currenty no alerts.',
		moreInfo: 'More info',
	},
	productadvies: {
		title: 'Enter your trip and choose your route',
		intro: 'For which trip do you need a (season) ticket?',
		from: 'From',
		to: 'To',
		selectRoute: "Select the route you (usually) take and click 'Choose this route'.",
		button: 'Choose this route',
		assets: {
			TRAIN: ({ partition }) => `${partition.brand} to ${partition.direction}`,
			BUS: ({ partition }) =>
				/RRReis/.test(partition.brand) ? (
					<>
						<em>{partition.brand}</em> line {partition.traject}
					</>
				) : (
					`${partition.brand || partition.operatorName} ${partition.traject}`
				),
		},
		noTrips: 'No routes could be found for your trip.',
	},
	weer: {
		geolocationError: 'Unable to determine your location.',
	},
	locationAutocomplete: {
		placeholder: 'address, postal code, or station',
		loading: 'Searching...',
		noResults: {
			forQuery: ({ query }) => `No locations found for ${query}`,
			empty: 'Enter an address or station to find a location',
		},
		type: {
			station: 'Station',
			poi: 'POI',
			halte: 'Stop',
			adres: 'Address',
		},
	},
}

// ██╗  ██╗ ██████╗  ██████╗ ██╗  ██╗
// ██║  ██║██╔═══██╗██╔═══██╗██║ ██╔╝
// ███████║██║   ██║██║   ██║█████╔╝
// ██╔══██║██║   ██║██║   ██║██╔═██╗
// ██║  ██║╚██████╔╝╚██████╔╝██║  ██╗
// ╚═╝  ╚═╝ ╚═════╝  ╚═════╝ ╚═╝  ╚═╝
//

export const useDictionary = (prefix = null, language = null) => {
	// Check language
	const ctx = React.useContext(WidgetContext)
	const lang = language || (ctx ? ctx.language : 'nl')

	// Create result
	const result = useMemo(() => {
		const Data = lang === 'en' ? EN : NL
		const t = (key, attributes = {}) => {
			let k = prefix ? `${prefix}.${key}` : key
			const parts = k.split(/\./)
			let o = Data
			while (parts.length > 0) {
				const p = parts.shift()
				if (!o[p]) return `[${k}]`
				o = o[p]
			}
			if (typeof o === 'function') return o(attributes)
			return o
		}

		return {
			t,
		}
	}, [lang, prefix])

	return result
}
