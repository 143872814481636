import * as React from 'react'
export const HalteIcon = () => {
	return (
		<svg
			id='Layer_1'
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 421 800'
			width={20}
			height={26}>
			<path
				fill='currentcolor'
				d='M168.55,532H162.2c-25.84,0-51.68.4-77.5-.1C48.92,531.21,23,514.4,7.37,482.19,1.85,470.8,0,458.47,0,445.8Q0,296.55,0,147.31c0-20.84-.06-41.67,0-62.5.14-37.05,22.18-68,57.12-80.32A80.75,80.75,0,0,1,84.09,0q126.49,0,253,0c40.64.08,74.29,28,82.38,68A83.06,83.06,0,0,1,421,84.38c.08,121,.28,242-.07,363-.1,35.85-17.54,61.67-49.7,77.29C360.16,530,348.16,532,335.84,532q-38.5-.06-77,0h-5.95V755.73H168.55ZM365,140.32H56v3.26q0,151.74,0,303.49A29.63,29.63,0,0,0,56.5,453c2.84,13.9,14.07,23,28.58,23q85.5,0,171,0c26.5,0,53,.12,79.5-.12a32.14,32.14,0,0,0,13-2.6c11.16-5.19,16.49-14.33,16.48-26.75Q365,295.78,365,145Z'
			/>
		</svg>
	)
}
