import AwesomeDebouncePromise from 'awesome-debounce-promise'
import * as React from 'react'
import AsyncSelect from 'react-select/async'
import styled from 'styled-components'
import { apiCall } from '../../api/apiCalls'
import { Theme } from '../../theme'
import { useDictionary } from '../hooks/useDictionary'
import { useWidget } from '../hooks/useWidget'
import LineAutocompleteSelectOption from './LineAutocompleteSelectOption'
import VehicleIcon from '../gfx/VehicleIcon'

// ███████╗████████╗██╗   ██╗██╗     ███████╗██████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔══██╗
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ██║  ██║
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ██║  ██║
// ███████║   ██║      ██║   ███████╗███████╗██████╔╝
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚═════╝
//

const Container = styled.div`
	background: white;
	display: flex;
	width: 100%;
	align-items: center;
	border-radius: 4px;
	border: 1px solid ${Theme.colors.border.gray};
	height: 52px;

	input {
		height: 40px;
	}
`

const SingleValueLabel = styled.div`
	grid-area: 1/1/2/3;
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	color: hsl(0, 0%, 20%);
	margin-left: 2px;
	margin-right: 2px;
	box-sizing: border-box;

	pointer-events: none;

	line-height: 25px;
	font-size: 18px;
	color: ${Theme.colors.body};

	display: flex;
	align-items: center;
	gap: 8px;

	:focus & {
		background: red !important;
	}
`

// ███████╗███████╗██╗     ███████╗ ██████╗████████╗    ███████╗████████╗██╗   ██╗██╗     ███████╗
// ██╔════╝██╔════╝██║     ██╔════╝██╔════╝╚══██╔══╝    ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝
// ███████╗█████╗  ██║     █████╗  ██║        ██║       ███████╗   ██║    ╚████╔╝ ██║     █████╗
// ╚════██║██╔══╝  ██║     ██╔══╝  ██║        ██║       ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝
// ███████║███████╗███████╗███████╗╚██████╗   ██║       ███████║   ██║      ██║   ███████╗███████╗
// ╚══════╝╚══════╝╚══════╝╚══════╝ ╚═════╝   ╚═╝       ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝
//

const customStyles = {
	container: base => ({ ...base, width: '100%' }),
	control: base => ({
		...base,
		borderWidth: 0,
		borderRadius: 0,
		outline: 'none',
		boxShadow: 'none !important',
		padding: '0 8px 0 15px',
		height: '52px',
		background: 'transparent',
	}),
	valueContainer: base => ({
		...base,
		padding: 0,
	}),
	menu: base => ({
		...base,
		zIndex: 10001,
	}),
	placeholder: base => ({
		...base,
		color: Theme.colors.placeholder,
	}),
}

const SingleValue = ({ children, data: line, ...props }) => {
	return (
		<SingleValueLabel {...props}>
			<VehicleIcon vehicle={line.transport_type} />
			<span>
				{line.public_number} - {line.name}
			</span>
		</SingleValueLabel>
	)
}

// ███████╗███████╗ █████╗ ██████╗  ██████╗██╗  ██╗
// ██╔════╝██╔════╝██╔══██╗██╔══██╗██╔════╝██║  ██║
// ███████╗█████╗  ███████║██████╔╝██║     ███████║
// ╚════██║██╔══╝  ██╔══██║██╔══██╗██║     ██╔══██║
// ███████║███████╗██║  ██║██║  ██║╚██████╗██║  ██║
// ╚══════╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝ ╚═════╝╚═╝  ╚═╝
//

const searchFunctions = {}

const search = language => {
	const key = `${language}/rrreis`
	if (!searchFunctions[key])
		searchFunctions[key] = AwesomeDebouncePromise(
			async query => {
				// Get locations
				try {
					return await apiCall(`/lines`, {
						search: query,
						pageSize: 20,
						language,
						concession: 'rrreis',
					})
				} catch (error) {
					console.error(error)
					return []
				}
			},
			500,
			{}
		)

	return searchFunctions[key]
}

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//

const LineAutocomplete = ({ id, value, onChange }) => {
	const { t } = useDictionary('lines.search')
	const { language } = useWidget()

	return (
		<Container>
			<AsyncSelect
				// Loading
				cacheOptions
				loadOptions={search(language)}
				// Value
				inputId={id}
				name={'select-stop'}
				value={value ? value : null}
				onChange={value => onChange && onChange(value ? value : null)}
				// Config
				openMenuOnClick={false}
				menuPlacement={'bottom'}
				// Display
				styles={customStyles}
				placeholder={t('placeholder')}
				noOptionsMessage={({ inputValue }) =>
					t(`noResults.${inputValue.length === 0 ? 'empty' : 'forQuery'}`, { query: inputValue })
				}
				loadingMessage={() => t('loading')}
				isClearable
				// Components
				components={{
					IndicatorSeparator: null,
					DropdownIndicator: null,
					Option: LineAutocompleteSelectOption,
					SingleValue,
				}}
			/>
		</Container>
	)
}
export default LineAutocomplete
