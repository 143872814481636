import * as React from 'react'
import { useState } from 'react'
import useGeolocation from 'react-hook-geolocation'
import Skeleton from 'react-loading-skeleton'
import styled from 'styled-components'
import { apiCall } from '../../api/apiCalls'
import { Theme } from '../../theme'
import WidgetHeader from '../gfx/WidgetHeader'
import { useDictionary } from '../hooks/useDictionary'
import { usePrevious } from '../hooks/usePrevious'
import WeatherItem from '../weather/WeatherItem'

// ███████╗████████╗██╗   ██╗██╗     ███████╗██████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔══██╗
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ██║  ██║
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ██║  ██║
// ███████║   ██║      ██║   ███████╗███████╗██████╔╝
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚═════╝
//

const Container = styled.div`
	max-width: 370px;
	padding: 28px 20px;

	background: white;
	border: 1px solid ${Theme.colors.border.lightGray};
	border-radius: 4px;
`

const Error = styled.div`
	font-weight: 500;
	color: ${Theme.colors.red};
	margin: 1em 0 0;
`

const Items = styled.div`
	display: flex;
`

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//

const WeerWidget = () => {
	///////////////
	// Resources //
	///////////////

	const { t } = useDictionary('weer')

	///////////
	// State //
	///////////

	const [error, setError] = useState(null)
	const [city, setCity] = useState()
	const [currentWeather, setCurrentWeather] = useState(null)
	const [forecasts, setForecasts] = useState([null, null, null, null])

	const geolocation = useGeolocation({
		enableHighAccuracy: false,
		maximumAge: 10000000,
		timeout: 10000,
	})

	/////////////
	// Effects //
	/////////////

	// Load weather
	const load = React.useCallback(async (lat = null, lng = null) => {
		// Loading
		setError(null)

		try {
			// Make the call
			const result = await apiCall(`/weather${lat && lng ? `/${lat}/${lng}` : ''}`, {}, false)

			// Use city
			setCity(result.city)
			setCurrentWeather(result.weather)
			setForecasts(result.forecast.hourly.slice(1, 5))
		} catch (error) {
			setError(error.toString())
		}
	}, [])

	const previousLatitude = usePrevious(geolocation.latitude)
	React.useEffect(() => {
		// Don't update the location without a refresh
		if (previousLatitude) return

		// No error, no latitude? Still waiting
		if (!geolocation.error && !geolocation.latitude) return

		// Load now.
		load(geolocation.latitude, geolocation.longitude)
	}, [geolocation.latitude, geolocation.longitude, geolocation.error])

	return (
		<Container>
			<WidgetHeader title={city || <Skeleton width={180} />} />

			{/* Weather */}
			<Items>
				<WeatherItem weather={currentWeather} />
				{forecasts.map((forecast, index) => (
					<WeatherItem key={index} weather={forecast} />
				))}
			</Items>

			{/* Errors */}
			{geolocation.error && geolocation.error.message !== 'User denied Geolocation' && (
				<Error>{t('geolocationError')}</Error>
			)}
			{error && <Error>{error}</Error>}
		</Container>
	)
}
export default WeerWidget
