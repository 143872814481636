import QueryString from 'qs'
import { faCalendarAlt, faClock, faPlus, faSync } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { addWeeks, format, formatISO, isToday, isTomorrow, setMinutes } from 'date-fns'
import en from 'date-fns/locale/en-US'
import nl from 'date-fns/locale/nl'
import * as React from 'react'
import { useMemo } from 'react'
import { useCallback, useState } from 'react'
import styled, { css } from 'styled-components'
import { Config } from '../../config'
import { Theme } from '../../theme'
import DatePicker from '../forms/DatePicker'
import RadioOptions from '../forms/RadioOptions'
import LocationAutocomplete from '../forms/LocationAutocomplete'
import Button from '../gfx/Button'
import SwitchIcon from '../gfx/SwitchIcon'
import { useDictionary } from '../hooks/useDictionary'
import { useWidget } from '../hooks/useWidget'
import { setHours } from 'date-fns/esm'

// ███████╗████████╗██╗   ██╗██╗     ███████╗██████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔══██╗
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ██║  ██║
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ██║  ██║
// ███████║   ██║      ██║   ███████╗███████╗██████╔╝
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚═════╝
//
const SwitchLocations = styled.button`
	cursor: pointer;
	background: none;
	border: none;
	color: white;
	font-size: inherit;
	width: 140px;
	display: flex;
	align-items: center;
	justify-content: center;
`
const Container = styled.div`
	background: ${Theme.colors.green};
	padding: 16px 0;
`
const Title = styled.h1`
	font-size: 51px;
	margin: 0 0 16px;
	color: ${Theme.colors.white} !important;
	@media screen and (max-width: ${Theme.breakpoints.mobile}) {
		font-size: 36px;
	}
`
const P = styled.p`
	color: ${Theme.colors.white} !important;
	margin: 12px 0;
`
const Locations = styled.div`
	display: flex;
	flex-direction: row;
	@media screen and (max-width: ${Theme.breakpoints.mobile}) {
		flex-direction: column;
		> * {
			margin-bottom: 8px;
			&:last-child {
				margin-bottom: 0;
			}
		}
		${SwitchLocations} {
			display: none;
		}
	}
`

const Fields = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 16px;
	color: white;

	@media screen and (max-width: ${Theme.breakpoints.mobile}) {
		flex-direction: column;
		> * {
			margin-bottom: 8px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
`
const FieldGroup = styled.div`
	display: flex;
	> * {
		margin: 0 20px;
		&:first-child {
			margin-left: 0;
		}
		&:last-child {
			margin-right: 0;
		}
	}
	> .react-datepicker__tab-loop {
		margin-left: 0;
		margin-right: 0;
	}
	@media screen and (max-width: ${Theme.breakpoints.mobile}) {
		margin-bottom: 18px !important;
		> * {
			margin-left: 10px;
			margin-right: 10px;
		}
	}
`

const Field = styled.div`
	background: none;
	border: none;
	color: inherit;
	border-radius: 0;

	height: 52px;
	border-bottom: 1px solid white;
	display: flex;
	align-items: center;
	font-size: 18px;

	> svg {
		margin-right: 8px;
	}

	${props =>
		(props.as === 'button' || props.as === 'a') &&
		css`
			cursor: pointer;
			color: white !important;
			text-decoration: none;
		`}

	> input[type='time'], >input[type='date'] {
		display: block;
		padding: 0;
		border: none;
		background: transparent;
		width: auto;
		font-size: inherit;

		white-space: nowrap;

		&:focus {
			outline: none;
		}
		color: inherit;
		&::-webkit-calendar-picker-indicator {
			display: none;
		}
	}
	.moz-cover-clear {
		display: none;
	}
	> input[type='date'] {
		max-width: 100px;
	}
	> input[type='time'] {
		max-width: 70px;
	}
`
const Options = styled.div`
	display: flex;
	align-items: center;
	margin-right: 16px;
	flex-wrap: wrap;

	> * {
		margin: 0 20px;
		&:first-child {
			margin-left: 0;
		}
		&:last-child {
			margin-right: 0;
		}
	}

	.react-datepicker-popper {
		z-index: 10000;
	}

	@media screen and (max-width: ${Theme.breakpoints.mobile}) {
		> * {
			margin-right: 0;
			margin-left: 0;
		}
	}
`

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//

export const RRReisPlannerWidget = () => {
	///////////////
	// Resources //
	///////////////

	const { t } = useDictionary('rrreisplanner')
	const { device, language } = useWidget()

	///////////
	// State //
	///////////

	const [from, setFrom] = useState()
	const [to, setTo] = useState()
	const [useArrival, setUseArrival] = useState(false)
	const [date, setDate] = useState(new Date())
	const [time, setTime] = useState(format(new Date(), 'HH:mm'))

	// Min date
	const minDate = React.useMemo(() => new Date(), [])
	const maxDate = React.useMemo(() => addWeeks(new Date(), 7), [])

	// Textual date
	const dateLabel = React.useMemo(() => {
		// Today
		if (isToday(date)) return t('date.today')
		if (isTomorrow(date)) return t('date.tomorrow')
		return format(date, 'd MMMM', { locale: language === 'nl' ? nl : en })
	}, [date, t, language])

	/////////////
	// Actions //
	/////////////

	const switchLocations = React.useCallback(() => {
		const f = from
		setFrom(to ? { ...to } : null)
		setTo(f ? { ...f } : null)
	}, [from, to])

	const resetTime = useCallback(() => {
		setDate(new Date())
		setTime(format(new Date(), 'HH:mm'))
	}, [])

	const routeUrl = useMemo(() => {
		// Get at
		const [h = 0, m = 0] = time.split(/:/)
		const at = setMinutes(setHours(new Date(date), h), m)

		// Construct URL
		let url = `${Config.siteUrl}/${language}/rrreis/reisplanner/plannen?${QueryString.stringify({
			van: from ? from.id : undefined,
			naar: to ? to.id : undefined,
			[useArrival ? 'aankomst' : 'vertrek']: at ? formatISO(at, { representation: 'complete' }) : undefined,
		})}`

		return url
	}, [from, to, useArrival, date, time, language])

	return (
		<Container>
			<Title>{t('header')}</Title>
			<P>{t('intro')}</P>
			<Locations>
				<LocationAutocomplete
					value={from}
					onChange={v => setFrom(v)}
					name={'from'}
					label={t('location.from')}
				/>
				<SwitchLocations onClick={switchLocations}>
					<SwitchIcon />
				</SwitchLocations>

				<LocationAutocomplete value={to} onChange={v => setTo(v)} name={'to'} label={t('location.to')} />
			</Locations>
			<Fields>
				<Options>
					{/* Departure/arrival */}
					<FieldGroup>
						<RadioOptions
							labelledBy={`${t('departure')} / ${t('arrival')}`}
							options={[
								{ value: false, label: t('departure') },
								{ value: true, label: t('arrival') },
							]}
							onChange={v => setUseArrival(v)}
							value={useArrival}
						/>
					</FieldGroup>

					<FieldGroup>
						{/* Date */}
						{device === 'mobile' ? (
							<Field>
								<FontAwesomeIcon size={'lg'} icon={faCalendarAlt} />
								<input
									required
									aria-label={t('labels.date')}
									type='date'
									min={format(minDate, 'yyyy-MM-dd')}
									max={format(maxDate, 'yyyy-MM-dd')}
									value={format(date, 'yyyy-MM-dd')}
									onChange={e => e.target.valueAsDate && setDate(e.target.valueAsDate)}
								/>
							</Field>
						) : (
							<DatePicker
								minDate={minDate}
								maxDate={maxDate}
								selected={date}
								customInput={
									<Field as={'button'}>
										<FontAwesomeIcon size={'lg'} icon={faCalendarAlt} />
										{dateLabel}
									</Field>
								}
								onChange={date => date && setDate(date)}
							/>
						)}

						{/* Time */}
						<Field>
							<FontAwesomeIcon size={'lg'} icon={faClock} />
							<input
								required
								type='time'
								name={'time'}
								aria-label={t('labels.time')}
								value={time}
								onChange={e => setTime(e.currentTarget.value)}
							/>
						</Field>

						{/* Now */}
						<Field as={'button'} onClick={resetTime}>
							<FontAwesomeIcon size={'lg'} icon={faSync} />
							{t('now')}
						</Field>
					</FieldGroup>

					{/* Extra options */}
					<FieldGroup>
						<Field clickable href={`${routeUrl.replace(/\/plannen/, '')}&extra-opties`} as={'a'}>
							<FontAwesomeIcon size={'lg'} icon={faPlus} />
							{t('extraOptions')}
						</Field>
					</FieldGroup>
				</Options>
				<Button label={t('submit')} as={'a'} href={routeUrl} />
			</Fields>
		</Container>
	)
}
