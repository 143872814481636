import * as React from 'react'
import Skeleton from 'react-loading-skeleton'
import styled from 'styled-components'
import { useWidget } from '../hooks/useWidget'

// ███████╗████████╗██╗   ██╗██╗     ███████╗██████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔══██╗
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ██║  ██║
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ██║  ██║
// ███████║   ██║      ██║   ███████╗███████╗██████╔╝
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚═════╝
//

const Item = styled.div`
	width: 64px;
	margin-right: 10px;
	font-size: 18px;
	text-align: center;
	&:first-child {
		margin-right: 20px;
	}
`

const Time = styled.div``
const Temperature = styled.div``
const Icon = styled.div`
	margin: 8px 0;
`

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//

const IconSize = 54

const WeatherItem = ({ weather, time = null }) => {
	const { language } = useWidget()

	return (
		<Item title={weather ? weather.type.description : ''}>
			<Time>{weather ? weather.time || (language === 'nl' ? 'Nu' : 'Now') : <Skeleton width={30} />}</Time>
			<Icon>
				{weather ? (
					<img
						src={`https://openweathermap.org/img/wn/${weather.type.icon}@2x.png`}
						alt={weather.type.description}
						width={IconSize}
						height={IconSize}
					/>
				) : (
					<Skeleton circle width={IconSize} height={IconSize} />
				)}
			</Icon>
			<Temperature>{weather ? `${Math.round(weather.temperature)}º` : <Skeleton width={20} />}</Temperature>
		</Item>
	)
}
export default WeatherItem
