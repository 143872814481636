import { faCheckCircle, faCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import styled, { css } from 'styled-components'
import { Theme } from '../../theme'

// ███████╗████████╗██╗   ██╗██╗     ███████╗██████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔══██╗
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ██║  ██║
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ██║  ██║
// ███████║   ██║      ██║   ███████╗███████╗██████╔╝
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚═════╝
//

const Item = styled.button`
	font-size: 16px;
	padding: 15px 0;
	cursor: pointer;
	opacity: 0.7;
	background: none;
	border: none;

	&:hover {
		opacity: 1;
		color: ${Theme.colors.blue};
	}

	span {
		margin-left: 8px;
	}

	${props =>
		props.isSelected &&
		css`
			opacity: 1;
		`}
`

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//

const RadioItem = ({ label, onClick, isSelected = false }) => {
	return (
		<Item isSelected={isSelected} onClick={onClick} role='radio'>
			<FontAwesomeIcon
				icon={isSelected ? faCheckCircle : faCircle}
				color={isSelected ? Theme.colors.blue : Theme.colors.white}
			/>
			<span>{label}</span>
		</Item>
	)
}
export default RadioItem
