import { faClock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { Config } from '../../config'
import StopAutocomplete from '../forms/StopAutocomplete'
import Button from '../gfx/Button'
import WidgetHeader from '../gfx/WidgetHeader'
import { useDictionary } from '../hooks/useDictionary'
import { useWidget } from '../hooks/useWidget'

// ███████╗████████╗██╗   ██╗██╗     ███████╗██████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔══██╗
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ██║  ██║
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ██║  ██║
// ███████║   ██║      ██║   ███████╗███████╗██████╔╝
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚═════╝
//

const Container = styled.div`
	background: #eff6fb;
	padding: 28px 20px;
	border-radius: 4px;
	max-width: 400px;
`
const Label = styled.label`
	font-size: 18px;
	color: #191919;
	margin-bottom: 8px;
	display: block;
`
const Select = styled.div`
	margin-bottom: 12px;
`

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//

const HaltesWidget = props => {
	const { t } = useDictionary('haltes')
	const { language } = useWidget()

	const [stop, setStop] = React.useState()

	const baseUrl = useMemo(() => `${Config.siteUrl}/${language}/rrreis/haltes`, [language])
	const url = useMemo(() => (stop ? `${baseUrl}/naam/${stop.slug}` : baseUrl), [baseUrl, stop])

	const onChange = useCallback(
		stop => {
			setStop(stop)
			if (stop) window.location = `${baseUrl}/naam/${stop.slug}`
		},
		[language, baseUrl]
	)

	return (
		<Container>
			<WidgetHeader icon={<FontAwesomeIcon icon={faClock} />} title={t('title')} />
			<Label htmlFor={'select-stop'}>{t('intro')}</Label>
			<Select>
				<StopAutocomplete id={'select-stop'} onChange={onChange} />
			</Select>
			<Button as={'a'} href={url} label={t('button')} block />
		</Container>
	)
}
export default HaltesWidget
