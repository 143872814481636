import { formatISO, setHours, setMinutes } from 'date-fns'
import QueryString from 'qs'
import * as React from 'react'
import { useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import { getProductAdviceOptions } from '../../api/9292'
import { Config } from '../../config'
import { Theme } from '../../theme'
import LocationAutocomplete from '../forms/LocationAutocomplete'
import Radio from '../forms/Radio'
import Button from '../gfx/Button'
import ErrorMessage from '../gfx/ErrorMessage'
import { useDictionary } from '../hooks/useDictionary'
import { useWidget } from '../hooks/useWidget'
import SelectRoute from '../product-advice/SelectRoute'

// ███████╗████████╗██╗   ██╗██╗     ███████╗██████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔══██╗
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ██║  ██║
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ██║  ██║
// ███████║   ██║      ██║   ███████╗███████╗██████╔╝
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚═════╝
//

const Container = styled.div`
	max-width: 698px;
	padding: 28px 20px;

	text-align: left;

	background: ${Theme.colors.bg.green};
	color: ${Theme.colors.body};
	border-radius: 4px;
`
const Title = styled.h3`
	font-size: 21px;
	font-weight: bold;
	color: #007a4e;
	margin-bottom: 10px;
`
const P = styled.div`
	margin: 1em 0;
`
const Locations = styled.div`
	display: flex;
	> * {
		margin-bottom: 12px;
	}

	@media screen and (min-width: ${Theme.breakpoints.mobile}) {
		> * {
			width: 50%;
			margin: 0 12px;
			&:first-child {
				margin-left: 0;
			}
			&:last-child {
				margin-right: 0;
			}
		}
	}
	@media screen and (max-width: ${Theme.breakpoints.mobile}) {
		flex-direction: column;
	}
`

const Form = styled.div`
	${props =>
		props.disabled &&
		css`
			pointer-events: none;
			opacity: 0.7;
		`}
`

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//

const DEBUG = false
const FROM_DEFAULT_VALUE = DEBUG
	? JSON.parse(
			'{"id":"station-apeldoorn-osseveld","modelType":"directions.location","station_abbreviation":"apdo","display_name":"Apeldoorn Osseveld","type":"Station","type_name":"Treinstation","coordinate":{"id":6,"modelType":"directions.coordinate","latitude":52.215829,"longitude":6.005945},"place":{"id":"apeldoorn","modelType":"directions.place","name":"Apeldoorn","region_code":"GL","region_name":"Gelderland","show_region":false,"country_code":"NL","country_name":"Nederland","show_country":false,"coordinate":{"id":1,"modelType":"directions.coordinate","latitude":52.209439,"longitude":5.968695}}}'
	  )
	: undefined
const TO_DEFAULT_VALUE = DEBUG
	? JSON.parse(
			'{"id":"epe/bushalte-apeldoorns-kanaal","modelType":"directions.location","station_abbreviation":null,"display_name":"Apeldoorns Kanaal","type":"Stop","type_name":"Bushalte","coordinate":{"id":8,"modelType":"directions.coordinate","latitude":52.345339,"longitude":6.020724},"place":{"id":"epe","modelType":"directions.place","name":"Epe","region_code":"GL","region_name":"Gelderland","show_region":true,"country_code":"NL","country_name":"Nederland","show_country":false,"coordinate":{"id":7,"modelType":"directions.coordinate","latitude":52.349958,"longitude":5.984699}}}'
	  )
	: undefined

const ProductadviesWidget = () => {
	///////////////
	// Resources //
	///////////////

	const { t } = useDictionary('productadvies')
	const { language } = useWidget()

	///////////
	// State //
	///////////

	const [isLoading, setIsLoading] = useState(false)
	const [from, setFrom] = React.useState(FROM_DEFAULT_VALUE)
	const [to, setTo] = React.useState(TO_DEFAULT_VALUE)
	const [modalities, setModalities] = React.useState('Bus,Train')

	const [trips, setTrips] = React.useState([null, null, null])
	const [selectedIndex, setSelectedIndex] = React.useState(-1)
	const [error, setError] = React.useState(null)

	/////////////
	// Effects //
	/////////////

	const loadOptions = React.useCallback(async () => {
		// Reset
		setTrips([null, null, null])
		setSelectedIndex(-1)
		setError(null)
		if (!from || !to) return
		setIsLoading(true)

		try {
			// Get the data
			const { error, trips } = await getProductAdviceOptions(language)(from, to, modalities)

			setError(error)
			setTrips(trips)
			setSelectedIndex(0)
		} catch (error) {
			console.error(error)
			setError(error)
		} finally {
			setIsLoading(false)
		}
	}, [from, to, modalities, isLoading, language])
	React.useEffect(() => {
		loadOptions()
	}, [from, to, modalities, language])

	////////////
	// Submit //
	////////////
	const submitUrl = useMemo(() => {
		if (!trips || selectedIndex === -1) return null

		// Get the trip
		const trip = trips[selectedIndex]
		if (!trip) return null
		const { payload } = trip
		const { date, time } = payload

		// Format the date
		const [h = 0, m = 0] = time.split(/:/)
		const at = setMinutes(setHours(new Date(date), h), m)

		return `${Config.siteUrl}/${language}/rrreis/reisplanner/plannen/${
			trip.originalIndex
		}/advies?${QueryString.stringify({
			van: payload.from_location,
			naar: payload.to_location,
			vertrek: formatISO(at, { representation: 'complete' }),
			voertuigen: payload.vehicle_types
				.split(/,/)
				.map(vt => {
					switch (vt) {
						case 'Train':
							return 'trein'

						default:
							return vt.toLowerCase()
					}
				})
				.join('-'),
		})}`
	}, [trips, selectedIndex, language])

	return (
		<Container>
			<Title>{t('title')}</Title>
			<P>{t('intro')}</P>

			<Form disabled={isLoading}>
				<Locations>
					<LocationAutocomplete value={from} onChange={v => setFrom(v)} label={t('from')} />
					<LocationAutocomplete value={to} onChange={v => setTo(v)} label={t('to')} />
				</Locations>
				<Radio
					value={modalities}
					onChange={v => setModalities(v)}
					options={[
						{ label: 'Bus en/of trein', value: 'Bus,Train' },
						{ label: 'Alleen bus', value: 'Bus' },
					]}
				/>
			</Form>

			{from && to && (
				<>
					<P>{t('selectRoute')}</P>
					<SelectRoute
						trips={trips}
						selectedIndex={selectedIndex}
						onChange={index => !isLoading && setSelectedIndex(index)}
					/>

					{/* Errors */}
					{trips.length === 0 && <ErrorMessage error={t('noTrips')} />}
					{error && <ErrorMessage error={error} />}

					{/* Submit */}
					{submitUrl && <Button as={'a'} role={'button'} href={submitUrl} label={t('button')} />}
				</>
			)}
		</Container>
	)
}
export default ProductadviesWidget
