import './publicPath'
import React from 'react'
import ReactDOM from 'react-dom'
import { RRReisPlannerWidget } from './components/widgets/RRReisPlannerWidget'
import { WidgetProvider } from './components/hooks/useWidget'
import NotFound from './components/widgets/NotFound'
import HaltesWidget from './components/widgets/HaltesWidget'
import VerstoringenWidget from './components/widgets/VerstoringenWidget'
import WeerWidget from './components/widgets/WeerWidget'
import ProductadviesWidget from './components/widgets/ProductadviesWidget'

function getWidget(key) {
	switch (key) {
		case 'productadvies':
			return ProductadviesWidget

		case 'weer':
			return WeerWidget

		case 'haltes':
			return HaltesWidget

		case 'verstoringen':
			return VerstoringenWidget

		case 'rrreisplanner':
			return RRReisPlannerWidget

		default:
			return NotFound
	}
}

export function init({ containerId, container, props = {} } = {}) {
	let loadContainer = container || document.getElementById(containerId)

	// Get widget class
	const { widget, ...other } = props
	const Widget = getWidget(props.widget)

	ReactDOM.render(
		<WidgetProvider value={other}>
			<Widget {...other} />
		</WidgetProvider>,
		loadContainer
	)
}
export function clean({ container } = {}) {
	if (container) {
		ReactDOM.unmountComponentAtNode(container)
	}
}

if (process.env.NODE_ENV === 'development') {
	;['productadvies', 'rrreisplanner', 'haltes', 'weer', 'verstoringen'].forEach(key => {
		const el = document.getElementById(key)
		if (el) init({ containerId: key, props: { widget: key, language: 'nl', device: 'desktop' } })
	})
}
