import * as React from 'react'
import styled, { css } from 'styled-components'
import { Theme } from '../../theme'

// ███████╗████████╗██╗   ██╗██╗     ███████╗██████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔══██╗
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ██║  ██║
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ██║  ██║
// ███████║   ██║      ██║   ███████╗███████╗██████╔╝
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚═════╝
//

const Btn = styled.button`
	height: 52px;
	line-height: 1;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	text-decoration: none;
	padding: 10px;
	width: 280px;
	background: ${Theme.colors.primary};
	border: none;
	color: white;
	font-size: 18px;
	font-weight: 500;
	border-radius: 4px;
	cursor: pointer;
	border: 1px solid white;

	${props =>
		props.block &&
		css`
			width: 100%;
		`}

	@media screen and (max-width: ${Theme.breakpoints.mobile}) {
		width: 100%;
	}
`

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//

const Button = ({ label = undefined, children, ...other }) => {
	return (
		<Btn {...other}>
			{label}
			{children}
		</Btn>
	)
}
export default Button
