import nl from 'date-fns/locale/nl'
import * as React from 'react'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useWidget } from '../hooks/useWidget'
import styled from 'styled-components'
import { Theme } from '../../theme'

registerLocale('nl', nl)

// ███████╗████████╗██╗   ██╗██╗     ███████╗██████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔══██╗
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ██║  ██║
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ██║  ██║
// ███████║   ██║      ██║   ███████╗███████╗██████╔╝
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚═════╝
//

const DatePickerContainer = styled.div`
	// Arrows in Month-navigation
	.react-datepicker__navigation--next {
		border-left-color: ${Theme.colors.primary} !important;
	}
	.react-datepicker__navigation--previous {
		border-right-color: ${Theme.colors.primary} !important;
	}
`

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//

const DatePicker = props => {
	const { language } = useWidget()

	return (
		<DatePickerContainer>
			<ReactDatePicker
				popperProps={{
					positionFixed: true,
				}}
				locale={language}
				dateFormat={'d MMMM yyyy'}
				{...props}
			/>
		</DatePickerContainer>
	)
}
export default DatePicker
