import { faHome, faMapPin, faRoad, faTrain } from '@fortawesome/free-solid-svg-icons'

export const getAutocompleteIcon = location => {
	switch (location.type) {
		case 'Address':
			return faHome

		case 'Street':
			return faRoad

		case 'Station':
			return faTrain

		case 'Postcode':
		case 'Poi':
		case 'Place':
		case 'LatLong':
		default:
			return faMapPin
	}
}
